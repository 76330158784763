import { handleActions } from 'redux-actions';
import { Common } from 'types/Common';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CommonState } from 'types/store/CommonState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

import { authActions } from '../actions';

const DEFAULT_STATE: CommonState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<CommonState, any>(
  {
    ...createAsyncReducers<Common>('USER/GET_PAYMENT_METHOD'),
    ...createAsyncReducers<Common>('USER/DELETE_PAYMENT_METHOD'),
    ...createAsyncReducers<Common>('USER/UPDATE_PAYMENT_METHOD'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;
