import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { JournalDescriptionState } from 'types/store/JournalDescriptionState';
import { asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: JournalDescriptionState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<JournalDescriptionState, any>(
  {
    ...createAsyncReducers('JOURNAL_DESCRIPTION/GET_JOURNAL_DESCRIPTION'),
  },
  DEFAULT_STATE
);

export default reducer;
