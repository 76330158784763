import { handleActions } from 'redux-actions';
import { City } from 'types/City';
import { Country } from 'types/Country';
import { AsyncData } from 'types/store/AsyncData';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { LocationsState } from 'types/store/CountryState';
import { asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: LocationsState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<LocationsState, any>(
  {
    ...createAsyncReducers('COUNTRY/GET_CITIES'),
  },
  DEFAULT_STATE
);

export default reducer;
