import { handleActions } from 'redux-actions';
import { countryActions } from 'store/actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CountryState } from 'types/store/CountryState';
import { asyncData, presetReducers } from 'utils/Redux';

const DEFAULT_STATE: CountryState = {
  ...{ countryCode: 'AU', countryName: 'Australia' },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<CountryState, any>(
  {
    [String(countryActions.set)]: presetReducers.makeSetter<CountryState>(),
  },
  DEFAULT_STATE
);

export default reducer;
