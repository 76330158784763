import { handleActions } from 'redux-actions';
import { StagingAuthState } from 'types/store/StagingAuth';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

import { stagingAuth } from '../actions';

const DEFAULT_STATE: StagingAuthState = { password: '' };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<StagingAuthState, any>(
  {
    [String(stagingAuth.set)]: presetReducers.makeSetter<StagingAuthState>(),
  },
  DEFAULT_STATE
);

export default reducer;
