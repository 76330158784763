import { handleActions } from 'redux-actions';
import { Common } from 'types/Common';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CommonState } from 'types/store/CommonState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: CommonState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<CommonState, any>(
  {
    ...createAsyncReducers<Common>('COMMON/CONTACT_US'),
  },
  DEFAULT_STATE
);

export default reducer;
