let friendbuyAPI;

if (!friendbuyAPI) {
  window['friendbuyAPI'] = friendbuyAPI = window['friendbuyAPI'] || [];
  // registers your merchant using your merchant ID found in the
  // retailer app https://retailer.friendbuy.io/settings/general
  friendbuyAPI.merchantId =
    import.meta.env.VITE_NODE_ENV === 'prod'
      ? 'cc4953b9-5059-4247-aa60-0d4d424e57a5'
      : '85ed6d74-22f9-4f87-af86-0c52f4d253c8';

  friendbuyAPI.push(['merchant', friendbuyAPI.merchantId]);

  // load the merchant SDK and your campaigns
  (function (f, r, n, d, b, u, y) {
    while ((u = n.shift())) {
      (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
      b.async = 1;
      b.src = u;
      y.parentNode.insertBefore(b, y);
    }
  })(document, 'script', [
    'https://static.fbot.me/friendbuy.js',
    'https://campaign.fbot.me/' + friendbuyAPI.merchantId + '/campaigns.js',
  ]);
}

(function () {
  // config option
  var bookingUrl = 'https://bookings.unyoked.co/stay/book';

  // text overrides
  BE.gadget.cart.text.showEmptyCart = 'Remove all from cart';
  BE.gadget.cart.text.noItems = '';

  // initialise the cart gadget
  var gadgetOptions = {
    vcID: 368,
    bookingURL: bookingUrl,
    autoCollapse: true,
    showPromoCode: false,
    showGuestLogin: false,
    showBookingTimer: false,
    showEmptyCartButton: true,
    autoFillQuestionsAlreadyAsked: true,
    autoFillAndHideQuestionsAlreadyAsked: true,
    collapseSimilarCartItems: true,
    theme: ['responsive', 'button'],
  };
  BE.gadget.cart('#toolbar-cart', gadgetOptions);
});
