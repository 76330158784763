import { handleActions } from 'redux-actions';
import { ResetPassword } from 'types/ResetPassword';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { StagingAuthState } from 'types/store/StagingAuth';
import { UpdatePasswordState } from 'types/store/UpdatePasswordState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

import { authActions, stagingAuth } from '../actions';

const DEFAULT_STATE: UpdatePasswordState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<UpdatePasswordState, any>(
  {
    ...createAsyncReducers<ResetPassword>('AUTH/UPDATE_PASSWORD'),
    [String(authActions.resetUpdatePasswordState)]:
      presetReducers.makeReset(DEFAULT_STATE),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;
