import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { EntryState } from 'types/store/EntryState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: EntryState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<EntryState, any>(
  {
    ...createAsyncReducers('CONTENTFUL/GET_ENTRY'),
  },
  DEFAULT_STATE
);

export default reducer;
