import { handleActions } from 'redux-actions';
import { presetReducers } from 'utils/Redux';

import { cabinFilterActions } from '../actions';
import { Nullable } from 'types/util/Nullable';
import { CabinFilter } from 'types/CabinFilter';

type State = Nullable<CabinFilter>

const DEFAULT_STATE: State = {
  
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<State, any>(
  {
    [String(cabinFilterActions.updated)]: presetReducers.makeSetter<State>(),
    [String(cabinFilterActions.clear)]:
      presetReducers.makeReset<State>(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;
