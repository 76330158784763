import { handleActions } from 'redux-actions';
import { Common, WaitlistForm } from 'types/Common';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { WaitlistFormState } from 'types/store/CommonState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: WaitlistFormState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<WaitlistFormState, any>(
  {
    ...createAsyncReducers<WaitlistForm>('COMMON/GET_WAITLIST_FORM'),
  },
  DEFAULT_STATE
);

export default reducer;
