import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { OffersState } from 'types/store/EntitiesState';
import { asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: OffersState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<OffersState, any>(
  {
    ...createAsyncReducers('ENTITY/GET_OFFERS'),
  },
  DEFAULT_STATE
);

export default reducer;
