import { handleActions } from 'redux-actions';
import { FilterState } from 'types/store/FilterState';
import { presetReducers } from 'utils/Redux';

import { filterActions } from '../actions';

const DEFAULT_STATE: FilterState = {
  location: {
    country: 'AU',
    address: '',
    latitude: 0,
    longitude: 0,
  },
  startDate: '',
  endDate: '',
  guests: '',
  beds: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<FilterState, any>(
  {
    [String(filterActions.updated)]: presetReducers.makeSetter<FilterState>(),
    [String(filterActions.clear)]:
      presetReducers.makeReset<FilterState>(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;
