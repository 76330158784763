import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { FeatureFlagsState } from 'types/store/FeatureFlagState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: FeatureFlagsState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<FeatureFlagsState, any>(
  {
    ...createAsyncReducers(`FEATURE_FLAG/GET_FEATURE_FLAGS`),
  },
  DEFAULT_STATE
);

export default reducer;
