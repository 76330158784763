import queryString from 'query-string';
import {
  applyMiddleware,
  compose,
  createStore,
  Reducer,
  Middleware,
  StoreEnhancer,
  Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Action, Bag, connectRoutes, Meta, redirect } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import {
  persistCombineReducers,
  persistStore,
  PersistConfig,
  Persistor,
  createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import ROUTES from 'routes';

import { routerActions } from './actions';
import reducers from './reducers';

const isProd: boolean = import.meta.env.VITE_NODE_ENV === 'prod';

const routerConfig = {
  initialDispatch: false,
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  onBeforeChange: (dispatch: Function, getState: Function, bag: Bag) => {
    const action = bag.action;

    if (
      action &&
      action.type == 'ROUTER/RESULTS' &&
      action.payload &&
      (action.payload as any)['country'] &&
      (action.payload as any)['country'] == 'GB'
    ) {
      dispatch(
        redirect({
          type: action.type,
          payload: { ...action.payload, country: 'UK' },
        } as Action)
      );
    }
  },

  displayConfirmLeave: (message: string, callback: any) => {
    const canLeave = (can: any) => {
      store.dispatch({ type: 'HIDE_BLOCK_NAVIGATION_MODEL' });
      return callback(can);
    };

    store.dispatch({
      type: 'SHOW_BLOCK_NAVIGATION_MODAL',
      payload: { message, canLeave },
    });
  },
  // onAfterChange: (dispatch: Function, getState: Function, bag: Bag) => {
  //   const action = bag.action;
  //   const curLoc = (action.meta as Meta)?.location.current;
  //   const prevLoc = (action.meta as Meta)?.location.prev;

  //   const curUri = curLoc.pathname + (curLoc.search ? `?${curLoc.search}` : '');
  //   if (prevLoc.pathname) {
  //     const prevUri = prevLoc.pathname + (prevLoc.search ? `?${prevLoc.search}` : '');

  //     if (curUri == prevUri) return;
  //   }

  //   const ga = (window as any).ga;
  //   if (typeof ga == 'function') {
  //     ga('set', 'page', curUri);
  //     ga('send', 'pageview');
  //   }
  // }
};

const MIGRATION_DEBUG = false;

const migrations = {
  3: (state: any) => {
    return {
      ...state,
      location: undefined,
    };
  },
  4: (state: any) => {
    return {
      ...state,
      location: undefined,
      checkout: undefined,
      currentEntity: undefined,
      user: undefined,
      token: undefined,
    };
  },
};

const persistConfig: PersistConfig = {
  key: 'root',
  storage,
  version: 4,
  whitelist: [
    'user',
    'profile',
    'filter',
    'country',
    // 'location',
    'paymentIntent',
    'paymentAfterpay',
    'stagingAuth',
    'checkout',
    'token',
  ],

  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

// Transform ROUTES to have `ROUTER/` prefix and map to path
const routePaths = Object.keys(ROUTES).reduce(
  (processedRoutes, routeKey) => ({
    ...processedRoutes,
    [`ROUTER/${routeKey}`]: ROUTES[routeKey].confirmLeave
      ? {
          path: ROUTES[routeKey].path,
          confirmLeave: ROUTES[routeKey].confirmLeave,
        }
      : ROUTES[routeKey].path,
  }),
  {}
);

const router: {
  reducer: Reducer;
  middleware: Middleware;
  enhancer: StoreEnhancer;
  initialDispatch?: () => void;
} = connectRoutes(routePaths, routerConfig);

const combinedReducer: Reducer = persistCombineReducers(
  persistConfig,
  // @ts-ignore
  {
    ...reducers,
    location: router.reducer,
    blockNavigation: (state: any = {}, action: any = {}) => {
      switch (action.type) {
        case 'SHOW_BLOCK_NAVIGATION_MODAL':
          const { message, canLeave } = action.payload;
          return { message, canLeave };
        case 'HIDE_BLOCK_NAVIGATION_MODEL':
          return {};
        default:
          return state;
      }
    },
  }
);

const middlewareEnhancer: Function = applyMiddleware(
  router.middleware,
  thunk,
  promise
);

const composedEnhancers: StoreEnhancer = (
  isProd ? compose : composeWithDevTools
)(
  router.enhancer,
  // @ts-ignore
  middlewareEnhancer
);

const store: Store = createStore(combinedReducer, composedEnhancers);

const persistor: Persistor = persistStore(store, undefined, () => {
  if (router.initialDispatch !== undefined) router.initialDispatch();
});

export default {
  store,
  persistor,
};
