import { handleActions } from 'redux-actions';
import { entityActions } from 'store/actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { EntitiesState } from 'types/store/EntitiesState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: EntitiesState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<EntitiesState, any>(
  {
    ...createAsyncReducers('ENTITY/GET'),
  },
  DEFAULT_STATE
);

export default reducer;
