import blogs from './blogs';
import bookingQuestions from './bookingQuestions';
import bookings from './bookings';
import cabinFilter from './cabinFilter';
import calendarEntities from './calendarEntities';
import categories from './categories';
import checkout from './checkout';
import contact from './contact';
import contentful from './contentful';
import country from './country';
import createAccount from './createAccount';
import currentEntity from './currentEntity';
import currentJournal from './currentJournal';
import entities from './entities';
import exampleListings from './exampleListings';
import faq from './faq';
import featureFlags from './featureFlag';
import filter from './filter';
import forgotPassword from './forgotPassword';
import home from './home';
import journalDescription from './journalDescription';
import journals from './journals';
import locations from './locations';
import offers from './offers';
import paymentAfterpay from './paymentAfterpay';
import paymentIntent from './paymentIntent';
import paymentMethod from './paymentMethod';
import profile from './profile';
import provisions from './provisions';
import redeemVoucher from './redeemVoucher';
import resetPassword from './resetPassword';
import stagingAuth from './stagingAuth';
import subscribe from './subscribe';
import token from './token';
import uniquePage from './uniquePage';
import updatePassword from './updatePassword';
import user from './user';
import vouchers from './vouchers';
import waitlist from './waitlist';
import waitlistForm from './waitlistForm';

export default {
  user,
  token,
  exampleListings,
  profile,
  createAccount,
  forgotPassword,
  resetPassword,
  contact,
  home,
  subscribe,
  filter,
  cabinFilter,
  calendarEntities,
  blogs,
  journals,
  currentJournal,
  entities,
  currentEntity,
  waitlist,
  updatePassword,
  paymentMethod,
  paymentIntent,
  paymentAfterpay,
  bookings,
  vouchers,
  redeemVoucher,
  provisions,
  faq,
  uniquePage,
  contentful,
  country,
  journalDescription,
  locations,
  featureFlags,
  waitlistForm,
  categories,
  offers,
  bookingQuestions,
  stagingAuth,
  checkout,
};
