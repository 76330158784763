import { handleActions } from 'redux-actions';
import { Contentful } from 'types/Contentful';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { JournalState } from 'types/store/JournalState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: JournalState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<JournalState, any>(
  {
    ...createAsyncReducers('JOURNAL/GET_BY_ID'),
  },
  DEFAULT_STATE
);

export default reducer;
