import { handleActions } from 'redux-actions';
import { checkoutActions } from 'store/actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CheckoutState } from 'types/store/CheckoutState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: CheckoutState = {
  publicProps: undefined,
  currentUser: undefined,
  currentEntity: undefined,
  payload: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<CheckoutState, any>(
  {
    [String(checkoutActions.setProps)]:
      presetReducers.makeMerge<CheckoutState>(),
  },
  DEFAULT_STATE
);

export default reducer;
