import { handleActions } from 'redux-actions';
import { Contentful } from 'types/Contentful';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { BlogState } from 'types/store/BlogState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: BlogState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<BlogState, any>(
  {
    ...createAsyncReducers('BLOG/GET_ALL'),
  },
  DEFAULT_STATE
);

export default reducer;
