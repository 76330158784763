import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { UniquePageState } from 'types/store/UniquePageState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE: UniquePageState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<UniquePageState, any>(
  {
    ...createAsyncReducers('UNIQUEPAGE/GET_BY_NAME'),
    ...createAsyncReducers('UNIQUEPAGE/GET_SCIENCEPAGE'),
  },
  DEFAULT_STATE
);

export default reducer;
