import { handleActions } from 'redux-actions';
import { authActions, userActions } from 'store/actions';
import { Common } from 'types/Common';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CommonState } from 'types/store/CommonState';
import { asyncData, createAsyncReducers, presetReducers } from 'utils/Redux';

const DEFAULT_STATE: CommonState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<CommonState, any>(
  {
    ...createAsyncReducers<Common>('USER/GET_PAYMENT_AFTERPAY'),
    [String(userActions.setPaymentAfterpay)]: presetReducers.makeMerge(),
    [String(userActions.clearPaymentAfterpay)]: presetReducers.makeReset(DEFAULT_STATE),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;