import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Router } from 'components/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import storeConfig from 'store';
import './global.css';

import RouteComponents from './routes';
import * as serviceWorker from './serviceWorker';
import theme from './themeConfig/theme';
import './themeConfig/colors.css';
import 'friendbuy';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
const searchClient = algoliasearch(
  'I70210T0HW',
  '05d6489917913da99b0d624f418b50a3'
);

// TODO: change when auth is rolled out
const { localStorage } = window;
const visitCount = localStorage.getItem('visitCount');
if (!visitCount) localStorage.setItem('visitCount', '1');
else localStorage.setItem('visitCount', (Number(visitCount) + 1).toString());

const root: HTMLElement | null = document.getElementById('root');
const queryClient = new QueryClient();

const googleClientID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

if (root) {
  ReactDOM.render(
    <GoogleOAuthProvider clientId={googleClientID}>
      <QueryClientProvider client={queryClient}>
        <InstantSearch searchClient={searchClient} indexName="Journals">
          <ThemeProvider theme={theme}>
            <Provider store={storeConfig.store}>
              <PersistGate persistor={storeConfig.persistor}>
                <Router components={RouteComponents} />
              </PersistGate>
            </Provider>
            <CssBaseline />
          </ThemeProvider>
        </InstantSearch>
      </QueryClientProvider>
    </GoogleOAuthProvider>,
    root
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
