import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { ProvisionsState } from 'types/store/ProvisionsState';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

import { authActions } from '../actions';

const DEFAULT_STATE: ProvisionsState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<ProvisionsState, any>(
  {
    ...createAsyncReducers('PROVISIONS/GET_ALL'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;
