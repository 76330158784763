import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { asyncData, createAsyncReducers } from 'utils/Redux';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<any, any>(
  {
    ...createAsyncReducers('HUBSPOT/BOOKING_QUESTIONS'),
  },
  DEFAULT_STATE
);

export default reducer;
