import { handleActions } from 'redux-actions';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';

import { authActions, vouchersActions } from '../actions';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions(
  {
    ...createAsyncReducers('VOUCHERS/REDEEM'),
    [String(vouchersActions.clear)]: presetReducers.makeReset(DEFAULT_STATE),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE
);

export default reducer;
